import serverAPI from '@/services/serverAPI'

/**
 * @param {Vuex.Store} store
 * @param {object}     payload
 */
const fetchInvitations = async (store, payload) => {
  const invitations = await serverAPI.fetchInvitations(
    payload.groupId,
    payload.roleId,
    payload.searchText
  )

  store.commit('setInvitations', invitations)
}

/**
 * @param   {Vuex.Store} store
 * @param   {object}     payload
 *
 * @returns {object[]}
 */
const addInvitations = async (store, payload) => {
  const invitations = await serverAPI.addInvitationBulk(payload.invitations)

  invitations.forEach(invitation => {
    store.commit('addInvitation', invitation)
    store.dispatch('updateUserPermission', {
      email: invitation.to_email,
      role: invitation.role,
      group: invitation.group
    })
  })

  return invitations
}

/**
 * @param   {Vuex.Store} store
 * @param   {object}     payload
 *
 * @param   {number}     payload.invitationId
 * @param   {number}     payload.roleId
 * @param   {boolean}    payload.refreshUser
 * @returns {object}
 */
const updateInvitation = async (
  store,
  { invitationId, roleId, refreshUser }
) => {
  const invitation = await serverAPI.updateInvitation(invitationId, roleId)

  store.commit('updateInvitation', invitation)

  if (refreshUser) {
    store.dispatch('fetchCognitoUserData')
  } else {
    store.dispatch('updateUserPermission', {
      email: invitation.to_email,
      role: invitation.role,
      group: invitation.group
    })
  }

  return invitation
}

/**
 * @param   {Vuex.Store} store
 * @param   {object}     payload
 *
 * @returns {object}
 */
const updateUserPermission = (store, payload) => {
  const user = store.getters.getCognitoUserData

  if (payload.email !== user.email) {
    return user
  }

  const newPermission = {
    role: payload.role,
    group: payload.group
  }
  const currentPermissionIndex = user.permissions.findIndex(
    permission => permission.group.id === newPermission.group.id
  )

  if (currentPermissionIndex > -1) {
    user.permissions.splice(currentPermissionIndex, 1, newPermission)
  } else {
    user.permissions.push(newPermission)
  }

  store.commit('setCognitoUserData', user)

  return user
}

/**
 * @param {Vuex.Store} store
 * @param {object}     payload
 */
const deleteInvitation = async (store, payload) => {
  await serverAPI.deleteInvitation(payload.invitationId, payload.roleId)

  store.commit('deleteInvitation', payload.invitationId)
}

/**
 * @param   {Vuex.Store} store
 *
 * @returns {object[]}
 */
const fetchRoles = async store => {
  const roles = await serverAPI.fetchRoles()

  store.commit('setRoles', roles)

  return roles
}

const actions = {
  fetchInvitations,
  addInvitations,
  updateInvitation,
  deleteInvitation,
  updateUserPermission,
  fetchRoles
}

export default actions
