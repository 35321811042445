import serverAPI from '@/services/serverAPI'
import RenderFarmMasterAPI from '@/services/renderFarmMasterAPI'
import { LIBRARY_TYPE } from '@/constants/libraryType'
import Utils from '@/services/utils'
import _camelCase from 'lodash/camelCase'
import { UPLOAD_STATUS, JOB_STATUS } from '@/constants/loadingStatus'
import { TRACKER_OBJECTS, TRACKER_EVENTS } from '@/constants/tracker'
import FeatureFlags from '@/services/featureFlags'

/**
 * @param   {Store}          context
 * @param   {Store.commit}   context.commit
 * @param   {Store.getters}  context.getters
 * @param   {Store.dispatch} context.dispatch
 * @param   {object}         payload
 * @param   {number}         payload.itemId
 * @param   {object}         payload."new"
 * @param   {string}         payload.libraryType
 *
 * @returns {object}
 */
const addStyleVersion = async (
  { commit, getters, dispatch },
  { itemId, new: newPayload, libraryType }
) => {
  libraryType = libraryType || getters.getActiveLibraryType

  const { styleId = itemId, model3D, renderEngine, isMain = false } = newPayload

  const file = model3D && model3D.length > 0 ? model3D[0].file : null
  const contributor = getters.getCognitoUserData
  let name = newPayload.name || Utils.getDescriptionFromFileName(file.name)

  if (name === '') {
    name = 'temporary name'
  }

  commit('setItemUploadStatus', {
    id: styleId,
    status: UPLOAD_STATUS.PENDING,
    libraryType
  })

  const fakeId = `fakeId-${Math.round(Math.random() * 100)}-${Math.round(
    Math.random() * 100
  )}`

  const engine = FeatureFlags.isVRayOnlyTeam()
    ? 'vray'
    : FeatureFlags.canTeamAccessRenderEngineChoice()
      ? renderEngine
      : 'blender'

  commit('setVersionsUploadInProgress', {
    styleId,
    fakeId,
    name,
    isMain,
    renderEngine: engine,
    contributor,
    libraryType
  })

  const newVersion = await serverAPI.addStyleVersion({
    styleId,
    file,
    name,
    renderEngine: engine,
    isMain
  })

  commit('deleteVersionsUploadInProgress', {
    styleId,
    fakeId,
    libraryType
  })

  commit('setItemUploadStatus', {
    id: styleId,
    status: UPLOAD_STATUS.COMPLETED,
    libraryType
  })

  const refreshedItem = await dispatch('refreshItem', {
    itemId: styleId,
    libraryType
  })

  dispatch('trackEvent', {
    object: TRACKER_OBJECTS.STYLE_VERSION,
    action: TRACKER_EVENTS.ADDED,
    item: refreshedItem,
    value: newVersion.id,
    label: newVersion.name,
    libraryType
  })

  return refreshedItem
}

/**
 * @param {Store}          context
 * @param {Store.dispatch} context.dispatch
 * @param {Store.commit}   context.commit
 * @param {Store.getters}  context.getters
 * @param {object}         payload
 * @param {number}         payload.styleId
 * @param {number}         payload.versionId
 * @param {number}         payload.optionId
 * @param {File[]}         payload.files
 * @param {string[]}       payload.urls
 * @param {boolean}        payload.isThumbnail
 * @param {string}         payload.libraryType
 */
const addStyleOptionImages = async (
  { dispatch, commit, getters },
  {
    styleId,
    versionId,
    optionId,
    files = [],
    urls = [],
    isThumbnail,
    libraryType
  }
) => {
  libraryType = libraryType || getters.getActiveLibraryType
  const images = [...files, ...urls]

  const imagePromises = images.map(async image => {
    const fakeId = `fakeId-${Math.round(Math.random() * 100)}-${Math.round(
      Math.random() * 100
    )}`

    if (optionId) {
      commit('setAllOptionsImagesUploadInProgress', {
        optionId,
        fakeId,
        libraryType
      })
    } else {
      commit('setAllVersionsImagesUploadInProgress', {
        styleId,
        versionId,
        fakeId,
        libraryType
      })
    }

    const payload = {
      styleId,
      versionId,
      optionId,
      isThumbnail
    }

    if (typeof image === 'string') {
      payload.url = image
    } else {
      payload.file = image
    }

    await serverAPI.addStyleOptionImage(payload)
  })

  const uploadedImages = await Promise.all(imagePromises)

  const refreshedItem = await dispatch('refreshItem', {
    itemId: styleId,
    libraryType
  })

  dispatch('trackEvent', {
    object: TRACKER_OBJECTS.STYLE_IMAGE,
    action: TRACKER_EVENTS.ADDED,
    item: refreshedItem,
    value: optionId || versionId,
    label: optionId
      ? `Images for option ${optionId}`
      : `Images for version ${versionId}`,
    libraryType
  })

  uploadedImages.forEach(() => {
    if (optionId) {
      commit('deleteAllOptionsImagesUploadInProgress', {
        optionId,
        libraryType
      })
    } else {
      commit('deleteAllVersionsImagesUploadInProgress', {
        styleId,
        versionId,
        libraryType
      })
    }
  })
}

/**
 * @param {Store}          context
 * @param {Store.dispatch} context.dispatch
 * @param {Store.getters}  context.getters
 * @param {object}         payload
 * @param {number}         payload.styleId
 * @param {number}         payload.versionId
 * @param {number}         payload.optionId
 * @param {number}         payload.imageId
 * @param {File}           payload.file
 * @param {boolean}        payload.isThumbnail
 * @param {string}         payload.libraryType
 */
const updateStyleOptionImage = async (
  { dispatch, getters },
  { styleId, versionId, optionId, imageId, file, isThumbnail, libraryType }
) => {
  libraryType = libraryType || getters.getActiveLibraryType

  const updatedImage = await serverAPI.updateStyleOptionImage({
    styleId,
    versionId,
    optionId,
    imageId,
    file,
    isThumbnail
  })

  dispatch('trackEvent', {
    object: TRACKER_OBJECTS.STYLE_IMAGE,
    action: TRACKER_EVENTS.UPDATED,
    item: updatedImage,
    value: imageId,
    label: `Image for option ${optionId}`,
    libraryType
  })

  await dispatch('refreshItem', {
    itemId: styleId,
    libraryType
  })
}

/**
 * @param {Store}          context
 * @param {Store.dispatch} context.dispatch
 * @param {Store.getters}  context.getters
 * @param {object}         payload
 * @param {number}         payload.styleId
 * @param {number}         payload.versionId
 * @param {number}         payload.optionId
 * @param {number}         payload.imageId
 * @param {string}         payload.libraryType
 */
const deleteStyleOptionImage = async (
  { dispatch, getters },
  { styleId, versionId, optionId, imageId, libraryType }
) => {
  libraryType = libraryType || getters.getActiveLibraryType

  await serverAPI.deleteStyleOptionImage({
    styleId,
    versionId,
    optionId,
    imageId
  })

  const refreshedItem = await dispatch('refreshItem', {
    itemId: styleId,
    libraryType
  })

  dispatch('trackEvent', {
    object: TRACKER_OBJECTS.STYLE_IMAGE,
    action: TRACKER_EVENTS.DELETED,
    item: refreshedItem,
    value: imageId,
    label: `Image for option ${optionId}`,
    libraryType
  })
}

/**
 * @param {Store}          context
 * @param {Store.commit}   context.commit
 * @param {Store.getters}  context.getters
 * @param {Store.dispatch} context.dispatch
 * @param {object}         payload
 * @param {object}         payload."new"
 * @param {string}         payload.libraryType
 */
const updateStyleVersion = async (
  { commit, getters, dispatch },
  { new: newPayload, libraryType }
) => {
  libraryType = libraryType || getters.getActiveLibraryType

  const { styleId, versionId, name, file, isMain } = newPayload

  commit('setItemUploadStatus', {
    id: styleId,
    status: UPLOAD_STATUS.PENDING,
    libraryType
  })

  const updatedVersion = await serverAPI.updateStyleVersion({
    styleId,
    versionId,
    name,
    file,
    isMain
  })

  commit('setItemUploadStatus', {
    id: styleId,
    status: UPLOAD_STATUS.COMPLETED,
    libraryType
  })

  const refreshedItem = await dispatch('refreshItem', {
    itemId: styleId,
    libraryType
  })

  dispatch('trackEvent', {
    object: TRACKER_OBJECTS.STYLE_VERSION,
    action: TRACKER_EVENTS.UPDATED,
    item: refreshedItem,
    value: updatedVersion.id,
    label: updatedVersion.name,
    libraryType
  })
}

/**
 * @param {Store}          context
 * @param {Store.getters}  context.getters
 * @param {Store.dispatch} context.dispatch
 * @param {object}         payload
 * @param {number}         payload.styleId
 * @param {number}         payload.versionId
 * @param {string}         payload.libraryType
 */
const setMainVersion = async (
  { getters, dispatch },
  { styleId, versionId, libraryType }
) => {
  libraryType = libraryType || getters.getActiveLibraryType

  const updatedVersion = await serverAPI.updateStyleVersion({
    styleId,
    versionId,
    isMain: true
  })

  const refreshedItem = await dispatch('refreshItem', {
    itemId: styleId,
    libraryType
  })

  dispatch('trackEvent', {
    object: TRACKER_OBJECTS.STYLE_VERSION,
    action: TRACKER_EVENTS.SET_AS_MAIN,
    item: refreshedItem,
    label: updatedVersion.name,
    value: updatedVersion.id,
    libraryType
  })
}

/**
 * @param {Store}          context
 * @param {Store.getters}  context.getters
 * @param {Store.dispatch} context.dispatch
 * @param {object}         payload
 * @param {number}         payload.styleId
 * @param {number}         payload.versionId
 * @param {string}         payload.libraryType
 */
const deleteStyleVersion = async (
  { getters, dispatch },
  { styleId, versionId, libraryType }
) => {
  libraryType = libraryType || getters.getActiveLibraryType

  await serverAPI.deleteStyleVersion({
    styleId,
    versionId
  })

  const refreshedItem = await dispatch('refreshItem', {
    itemId: styleId,
    libraryType
  })

  dispatch('trackEvent', {
    object: TRACKER_OBJECTS.STYLE_VERSION,
    action: TRACKER_EVENTS.DELETED,
    value: versionId,
    item: refreshedItem,
    libraryType
  })
}

/**
 * @param {Store}          context
 * @param {Store.getters}  context.getters
 * @param {Store.dispatch} context.dispatch
 * @param {object}         payload
 * @param {number}         payload.styleId
 * @param {number}         payload.versionId
 * @param {string}         payload.libraryType
 */
const rerenderStyleVersion = async (
  { getters, dispatch },
  { styleId, versionId, libraryType }
) => {
  libraryType = libraryType || getters.getActiveLibraryType

  await serverAPI.rerenderStyleVersion({
    styleId,
    versionId
  })

  const refreshedItem = await dispatch('refreshItem', {
    itemId: styleId,
    libraryType
  })

  dispatch('trackEvent', {
    object: TRACKER_OBJECTS.STYLE_VERSION,
    action: TRACKER_EVENTS.RERENDERED,
    value: versionId,
    item: refreshedItem,
    libraryType
  })
}

/**
 * @param {Store}         context
 * @param {Store.commit}  context.commit
 * @param {Store.getters} context.getters
 * @param {object}        payload
 * @param {number}        payload.itemId
 * @param {object}        payload.appliedFilters
 * @param {string}        payload.libraryType
 */
const overrideFiltersFromStyle = (
  { commit, getters },
  { itemId, appliedFilters, libraryType }
) => {
  libraryType = libraryType || getters.getActiveLibraryType
  const style = getters.getItemById({ id: itemId, libraryType })

  Object.entries(appliedFilters).forEach(([key, value]) => {
    const shouldOverrideFilter = !value || value.length > 0

    if (shouldOverrideFilter) {
      const filterKey = _camelCase(key)
      const filterGroupName = key
      const filterIds = (style[filterKey] && [style[filterKey]]) || []

      commit('setAppliedFilter', { filterGroupName, filterIds, libraryType })
    }
  })
}

/**
 * @param {Store}          context
 * @param {Store.getters}  context.getters
 * @param {Store.dispatch} context.dispatch
 * @param {object}         payload
 * @param {number}         payload.styleId
 * @param {string}         payload.plmCode
 * @param {number}         payload.seasonId
 * @param {string}         payload.libraryType
 */
const refreshPLMCode = async (
  { getters, dispatch },
  { styleId, plmCode, seasonId, libraryType }
) => {
  libraryType = libraryType || getters.getActiveLibraryType

  await serverAPI.refreshPLMCode({
    styleId,
    plmCode,
    seasonId
  })

  await dispatch('refreshItem', {
    itemId: styleId,
    libraryType
  })
}

/**
 * @param {Store}          context
 * @param {Store.getters}  context.getters
 * @param {Store.dispatch} context.dispatch
 * @param {object}         payload
 * @param {number}         payload.jobId
 * @param {number}         payload.versionId
 */
const fetchJobDetailWithTasks = async (
  { getters, dispatch },
  { jobId, versionId }
) => {
  const jobDetail = await RenderFarmMasterAPI.fetchJobDetail(
    jobId,
    'include-tasks'
  )

  // only relevant tasks contain colorway_name
  const tasks = jobDetail.tasks.filter(task => task.data.colorway_name)
  const successfulOptions = tasks
    .filter(task => task.status === JOB_STATUS.SUCCESS)
    .map(task => task.data.colorway_name)

  const libraryType = getters.getActiveLibraryType
  const item = getters.getItemDetail({ libraryType })
  const optionNames = getters
    .getOptionsByVersionId(versionId)
    .map(option => option.name)

  // refreshItem if successfully rendered options are not in store to show new options
  if (!successfulOptions.every(option => optionNames.includes(option))) {
    dispatch('refreshItem', {
      itemId: item.id,
      libraryType
    })
  }
}

/**
 * @param   {Store}          context
 * @param   {Store.commit}   context.commit
 * @param   {Store.getters}  context.getters
 * @param   {Store.dispatch} context.dispatch
 * @param   {number[]}       listIds
 * @returns {Array}
 */
const fetchVersionRenderStatus = async (
  { commit, getters, dispatch },
  listIds
) => {
  const libraryType = getters.getActiveLibraryType
  const item = getters.getItemDetail({ libraryType })

  if (item === null) {
    return
  }

  const versions = item.versions
  const statuses = await RenderFarmMasterAPI.fetchJobRenderStatus(listIds)

  const versionsNeedRefresh = []
  statuses.forEach(status => {
    const version = versions.find(version => version.renderJobId === status.id)
    const justSucceded =
      version.autorenderStatus !== null && status.status === JOB_STATUS.SUCCESS
    const justFailed =
      version.autorenderStatus !== null && status.status === JOB_STATUS.FAILED

    if (justSucceded || justFailed) {
      versionsNeedRefresh.push(version.id)
    }

    commit('updateVersionAutorenderStatus', {
      id: status.id,
      item,
      status: status.status,
      autorenderStatusMessage: status.status_detail
    })
  })

  if (versionsNeedRefresh.length > 0) {
    dispatch('refreshItem', {
      itemId: item.id,
      libraryType
    })
  }

  return statuses
}

/**
 * @param {Store}         context
 * @param {Store.commit}  context.commit
 * @param {Store.getters} context.getters
 * @param {object}        payload
 * @param {number}        payload.styleId
 * @param {number}        payload.versionId
 * @param {boolean}       payload.onlyUnassigned
 * @param {string}        payload.libraryType
 */
const fetchPlmOptions = async (
  { commit, getters },
  { styleId, versionId, onlyUnassigned, libraryType }
) => {
  libraryType = libraryType || getters.getActiveLibraryType

  const options = await serverAPI.fetchPlmOptions({
    styleId,
    versionId,
    onlyUnassigned
  })

  commit('setPlmOptions', {
    options,
    libraryType
  })
}

/**
 * @param {Store}          context
 * @param {Store.getters}  context.dispatch
 * @param {Store.dispatch} context.getters
 * @param {object}         payload
 */
const createOption = async ({ dispatch, getters }, payload) => {
  const libraryType = payload.libraryType || getters.getActiveLibraryType
  const styleId = payload.new.styleId

  const option = await serverAPI.addOption(payload.new)

  payload.new.optionId = option.id
  await dispatch('updateItemTags', {
    ...payload,
    libraryType
  })

  const refreshedItem = await dispatch('refreshItem', {
    itemId: styleId,
    libraryType
  })

  dispatch('trackEvent', {
    object: TRACKER_OBJECTS.STYLE_COLORWAY,
    action: TRACKER_EVENTS.ADDED,
    item: refreshedItem,
    value: option.id,
    label: option.name,
    libraryType
  })
}

/**
 * @param   {Store}          context
 * @param   {Store.getters}  context.dispatch
 * @param   {Store.dispatch} context.getters
 * @param   {object}         payload
 * @param   {object}         payload."new"
 * @param   {string}         payload.libraryType
 * @returns {object}
 */
const createOptionByPlm = async (
  { dispatch, getters },
  { new: newPayload, libraryType }
) => {
  libraryType = libraryType || getters.getActiveLibraryType
  const { styleId, name, versionId } = newPayload

  const newOption = await serverAPI.addOption({
    name,
    code: null,
    mainColorId: null,
    plmStatus: null,
    versionId,
    styleId
  })

  const item = await dispatch('refreshItem', {
    itemId: styleId,
    libraryType
  })

  dispatch('trackEvent', {
    object: TRACKER_OBJECTS.STYLE_COLORWAY,
    action: TRACKER_EVENTS.ADDED,
    value: newOption.id,
    label: `Option by PLM: ${newOption.id}`,
    item,
    libraryType
  })

  return item.versions
    .find(version => version.id === versionId)
    .options.find(option => option.id === newOption.id)
}

/**
 * @param {Store}          context
 * @param {Store.dispatch} context.dispatch
 * @param {Store.getters}  context.getters
 * @param {object}         payload
 */
const updateOption = async ({ dispatch, getters }, payload) => {
  const libraryType = payload.libraryType || getters.getActiveLibraryType
  const itemId = payload.itemId

  const {
    styleId,
    versionId,
    optionId,
    name,
    code,
    mainColorId,
    plmStatus,
    index
  } = payload.new || payload

  const updatedOption = await serverAPI.updateOption({
    styleId,
    versionId,
    optionId: itemId || optionId,
    name,
    code,
    mainColorId,
    plmStatus,
    index
  })

  if (index === undefined) {
    await dispatch('updateItemTags', {
      ...payload,
      libraryType
    })
  }

  const refreshedItem = await dispatch('refreshItem', {
    itemId: styleId,
    libraryType
  })

  dispatch('trackEvent', {
    object: TRACKER_OBJECTS.STYLE_COLORWAY,
    action: TRACKER_EVENTS.UPDATED,
    value: updatedOption.id,
    label: updatedOption.name,
    item: refreshedItem,
    libraryType
  })
}

/**
 * @param {Store}          context
 * @param {Store.dispatch} context.dispatch
 * @param {Store.getters}  context.getters
 * @param {object}         payload
 * @param {number}         payload.styleId
 * @param {number}         payload.versionId
 * @param {number}         payload.optionId
 * @param {string}         payload.libraryType
 */
const deleteOption = async (
  { dispatch, getters },
  { styleId, versionId, optionId, libraryType }
) => {
  libraryType = libraryType || getters.getActiveLibraryType

  await serverAPI.deleteOption({
    styleId,
    versionId,
    optionId
  })

  const refreshedItem = await dispatch('refreshItem', {
    itemId: styleId,
    libraryType
  })

  dispatch('trackEvent', {
    object: TRACKER_OBJECTS.STYLE_COLORWAY,
    action: TRACKER_EVENTS.DELETED,
    value: versionId,
    item: refreshedItem,
    libraryType
  })
}

/**
 * @param   {Store}         context
 * @param   {Store.getters} context.getters
 * @param   {object}        payload
 * @param   {number}        payload.itemId
 * @param   {object}        payload."new"
 * @param   {object}        payload.current
 * @param   {string}        payload.libraryType
 * @returns {object}
 */
const updateItemTags = async (
  { getters },
  { itemId, new: newPayload, current, libraryType }
) => {
  if (libraryType !== LIBRARY_TYPE.STYLE) {
    return
  }

  const {
    styleId = itemId,
    versionId,
    optionId,
    seasonId,
    groupId
  } = newPayload

  let { tags: newTags = [] } = newPayload

  const {
    seasonId: oldSeasonId,
    groupId: oldGroupId,
    tags: oldTags = []
  } = current

  // If style season or division were changed, tags must be carried over to the new selection.
  const itemChangedSeasonOrGroup =
    oldSeasonId !== seasonId || oldGroupId !== groupId

  if (itemId && itemChangedSeasonOrGroup) {
    newTags = newTags.map(({ text }) => ({ text }))
  }

  if (oldTags.length > 0 || newTags.length > 0) {
    const tagsToRemove = oldTags.filter(
      tag => !newTags.some(newTag => newTag.id === tag.id)
    )
    const tagPromisesToRemove = tagsToRemove.map(async tag => {
      await serverAPI.deleteItemTag({
        styleId,
        versionId,
        optionId,
        tagId: tag.id
      })
    })

    const tagsToAdd = newTags.filter(tag => !tag.id)
    const tagPromisesToAdd = tagsToAdd.map(async tag => {
      await serverAPI.createItemTag({
        styleId,
        versionId,
        optionId,
        text: tag.text
      })
    })

    // Parallel execution
    await Promise.all([...tagPromisesToRemove, ...tagPromisesToAdd])
  }
}

/**
 * @param   {Store}            context
 * @param   {Store.dispatch}   context.commit
 * @param   {object}           payload
 * @param   {number}           payload.styleId
 * @param   {string|undefined} payload.filterBy
 *
 * @returns {object}
 */
const fetchStyleEvents = async (
  { getters, commit },
  { styleId = null, filterBy = null }
) => {
  const libraryType = LIBRARY_TYPE.STYLE
  const filter = filterBy || getters.getStyleEventsFilter
  const id = styleId || getters.getItemDetail(libraryType).id

  commit('setStyleEventsFilter', { filter })

  const events = await serverAPI.fetchStyleEvents({
    styleId: id,
    filterBy: filter
  })

  commit('setStyleEvents', {
    events,
    libraryType
  })
}

/**
 * @param {Store}          context
 * @param {Store.dispatch} context.commit
 * @param {object}         payload
 * @param {number}         payload.filter
 */
const setStyleEventsFilter = ({ commit }, { filter }) => {
  commit('setStyleEventsFilter', { filter })
}

/**
 * @param {Store}          context
 * @param {Store.dispatch} context.dispatch
 * @param {object}         payload
 * @param {number}         payload.styleId
 * @param {string}         payload.text
 */
const addStyleComment = async ({ getters, dispatch }, { styleId, text }) => {
  const libraryType = getters.getActiveLibraryType
  const comment = await serverAPI.addStyleComment({
    styleId,
    text
  })

  dispatch('trackEvent', {
    object: TRACKER_OBJECTS.COMMENT,
    action: TRACKER_EVENTS.ADDED,
    item: comment,
    value: comment.id,
    label: `Comment for style ${styleId}`,
    libraryType
  })

  await dispatch('fetchStyleEvents', { styleId })
}

/**
 * @param {Store}          context
 * @param {Store.dispatch} context.dispatch
 * @param {object}         payload
 * @param {number}         payload.styleId
 * @param {number}         payload.commentId
 * @param {string}         payload.text
 */
const updateStyleComment = async (
  { getters, dispatch },
  { styleId, commentId, text }
) => {
  const libraryType = getters.getActiveLibraryType

  const comment = await serverAPI.updateStyleComment({
    styleId,
    commentId,
    text
  })

  dispatch('trackEvent', {
    object: TRACKER_OBJECTS.COMMENT,
    action: TRACKER_EVENTS.UPDATED,
    item: comment,
    value: comment.id,
    label: `Comment for style ${styleId}`,
    libraryType
  })

  await dispatch('fetchStyleEvents', { styleId })
}

/**
 * @param {Store}          context
 * @param {Store.getters}  context.getters
 * @param {Store.dispatch} context.dispatch
 * @param {object}         payload
 * @param {number}         payload.styleId
 * @param {number}         payload.commentId
 */
const deleteStyleComment = async (
  { getters, dispatch },
  { styleId, commentId }
) => {
  const libraryType = getters.getActiveLibraryType
  await serverAPI.deleteStyleComment({
    styleId,
    commentId
  })

  dispatch('trackEvent', {
    object: TRACKER_OBJECTS.COMMENT,
    action: TRACKER_EVENTS.DELETED,
    value: commentId,
    label: `Comment for style ${styleId}`,
    libraryType
  })

  await dispatch('fetchStyleEvents', { styleId })
}

/**
 * @param {Store}        context
 * @param {Store.commit} context.commit
 * @param {object}       payload
 * @param {object}       payload.styleId
 */
const resetStyleUnreadNotificationsCount = async ({ commit }, { styleId }) => {
  await serverAPI.resetStyleUnreadNotificationsCount({
    styleId
  })

  commit('resetStyleUnreadNotificationsCount')
}

// ////////////////////////////////////////////////////
// Build object
// ////////////////////////////////////////////////////
const actions = {
  addStyleVersion,
  addStyleOptionImages,
  createOption,
  createOptionByPlm,
  updateOption,
  deleteOption,
  updateStyleOptionImage,
  deleteStyleOptionImage,
  updateStyleVersion,
  deleteStyleVersion,
  rerenderStyleVersion,
  setMainVersion,
  overrideFiltersFromStyle,
  refreshPLMCode,
  fetchVersionRenderStatus,
  fetchJobDetailWithTasks,
  fetchPlmOptions,
  fetchStyleEvents,
  addStyleComment,
  updateStyleComment,
  deleteStyleComment,
  resetStyleUnreadNotificationsCount,
  updateItemTags,
  setStyleEventsFilter
}

export default actions
