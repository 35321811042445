<template>
  <div class="library-status-selector">
    <p v-if="currentVendor">
      {{ currentVendor.name }}
    </p>
    <ElementSelect
      v-else
      v-model="selectedVendor"
      placeholder="Vendor Company"
      size="small"
      class="item-vendor__selector"
      @change="updateSelectedVendor"
    >
      <ElementOption
        v-for="vendor in vendors"
        :key="vendor.id"
        :label="vendor.name"
        :value="vendor.id"
      />
    </ElementSelect>
  </div>
</template>

<script>
export default {
  name: 'VendorSelector',

  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    vendors: {
      type: Array,
      default: () => []
    }
  },

  emits: ['update-vendor'],

  data () {
    return {
      selectedVendor: null
    }
  },

  computed: {
    /**
     * @returns {object}
     */
    currentVendor () {
      if (this.selectedVendor) {
        return this.vendors.find(v => v.id === this.selectedVendor)
      }

      return null
    }
  },

  watch: {
    /**
     *
     */
    item () {
      this.selectedVendor = this.item.vendorCompanyId
    }
  },

  created () {
    if (this.item !== null) {
      this.selectedVendor = this.item.vendorCompanyId
    }
  },

  methods: {
    /**
     */
    updateSelectedVendor () {
      this.$confirm(
        `Are you sure you want to assign this style to the vendor company "${this.currentVendor.name}"?`,
        'Info',
        {
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          type: 'warning',
          showClose: false
        }
      )
        .then(() => {
          this.$emit('update-vendor', this.selectedVendor)
          this.selectedVendor = null
        })
        .catch(() => {
          this.selectedVendor = null
        })
    }
  }
}
</script>
