import VueTypes from 'vue-types'

/**
 * @type     {object}
 * @property {number}  [id]
 * @property {string}  [name]
 * @property {boolean} [external=true]
 */
const PermissionGroupShape = VueTypes.shape({
  id: VueTypes.number,
  name: VueTypes.string,
  external: VueTypes.bool.def(true)
})

/**
 * @type     {object}
 * @property {number} [id]
 * @property {string} [name]
 * @property {string} [display_name]
 * @property {string} [created_at]
 * @property {string} [updated_at]
 */
const PermissionRoleShape = VueTypes.shape({
  id: VueTypes.number,
  name: VueTypes.string,
  display_name: VueTypes.string,
  created_at: VueTypes.string,
  updated_at: VueTypes.string
})

/**
 * @type {object}
 */
const PermissionRoleFlagShape = VueTypes.shape({
  'create-invite': VueTypes.bool.def(false),
  'create-library': VueTypes.bool.def(false),
  'create-renders': VueTypes.bool.def(false),
  'create-style': VueTypes.bool.def(false),
  'delete-invite': VueTypes.bool.def(false),
  'delete-library': VueTypes.bool.def(false),
  'delete-renders': VueTypes.bool.def(false),
  'delete-style': VueTypes.bool.def(false),
  'update-invite': VueTypes.bool.def(false),
  'update-library': VueTypes.bool.def(false),
  'update-renders': VueTypes.bool.def(false),
  'update-style': VueTypes.bool.def(false),
  'visible-invite': VueTypes.bool.def(false),
  'visible-library': VueTypes.bool.def(false),
  'visible-renders': VueTypes.bool.def(false),
  'visible-style': VueTypes.bool.def(false),
  'visible-all_comment': VueTypes.bool.def(false)
})

/**
 * @type     {object}
 * @property {PermissionGroupShape}    [group]
 * @property {PermissionRoleShape}     [role]
 * @property {PermissionRoleFlagShape} [role_flags]
 */
const PermissionShape = VueTypes.shape({
  group: PermissionGroupShape,
  role: PermissionRoleShape,
  role_flags: PermissionRoleFlagShape
})

/**
 * @type     {object}
 * @property {string}   group
 * @property {string}   name
 * @property {string}   thumbnail
 * @property {string}   image
 * @property {Function} [method]
 */
const WorkspaceShape = VueTypes.shape({
  code: VueTypes.string.isRequired,
  name: VueTypes.string,
  image: VueTypes.string,
  thumbnail: VueTypes.string,
  method: VueTypes.func
})

/**
 * @type     {object}
 * @property {number}            id
 * @property {string}            email
 * @property {(string|null)}     api_key
 * @property {string}            username
 * @property {PermissionShape[]} [permissions]
 */
const UserShape = VueTypes.shape({
  id: VueTypes.number.isRequired,
  email: VueTypes.string.isRequired,
  api_key: VueTypes.oneOfType([String, null]).isRequired,
  username: VueTypes.string.isRequired,
  permissions: VueTypes.arrayOf(Object.values(PermissionShape)),
  workspaces: VueTypes.arrayOf(WorkspaceShape),
  is_hub_admin: VueTypes.bool,
  can_manage_users: VueTypes.bool
})

const ContributorShape = VueTypes.shape({
  id: VueTypes.number.isRequired,
  email: VueTypes.string.isRequired,
  username: VueTypes.string.isRequired
})

/**
 * @type {object}
 * @property {Invitation} item
 * @property {boolean}    canEditInvitation
 * @property {boolean}    canRemoveInvitation
 */
const InvitationShape = VueTypes.shape({
  item: VueTypes.object,
  canEditInvitation: VueTypes.bool,
  canRemoveInvitation: VueTypes.bool
})

const InviteeShape = VueTypes.shape({
  email: VueTypes.string.isRequired,
  isValid: VueTypes.bool
})

/**
 * @typedef  {object} Invitation
 * @property {number}               id
 * @property {PermissionGroupShape} group
 * @property {PermissionRoleShape}  role
 * @property {string}               to_email
 * @property {string}               created_at
 * @property {string}               updated_at
 */

export {
  ContributorShape,
  InvitationShape,
  InviteeShape,
  PermissionGroupShape,
  PermissionRoleFlagShape,
  PermissionRoleShape,
  PermissionShape,
  UserShape
}
