import store from '@/store/store'
import { ROLE_FLAG } from '@/constants/roleFlag'
import { USER_ROLE } from '@/constants/roleType'

/**
 * @returns {object}
 */
const getActivePermission = () => {
  return store.getters.getActivePermission
}

/**
 *
 * @param   {string}                 action
 * @param   {PermissionShape | null} permission
 *
 * @returns {boolean}
 */
export const canPermitAction = (action, permission = null) => {
  const selectedPermission = permission || getActivePermission()

  if (action.includes('visible') && selectedPermission.group?.name === 'All') {
    return true
  }

  return Boolean(
    selectedPermission &&
      selectedPermission.role_flags &&
      selectedPermission.role_flags[action]
  )
}

/**
 * @returns {boolean}
 */
export const canUserAccessUserManagement = () => {
  const user = store.getters.getCognitoUserData

  return user.is_hub_admin || user.can_manage_users
}

/**
 * @returns {boolean}
 */
export const isVendorUser = () => {
  const user = store.getters.getCognitoUserData

  return user.role === USER_ROLE.VENDOR.name
}

/**
 * Extends the invitation list by stating whether the provided user,
 * having a role in a specific permission,
 * can edit/remove each of the invitations.
 *
 * @param   {PermissionShape}      permission
 * @param   {object[]}             invitations
 *
 * @returns {InvitationShape[]|[]}
 */
export const applyPermissionsToInvitations = (permission, invitations) => {
  if (!permission) {
    return []
  }

  return invitations.map(invitation => ({
    item: invitation,
    canEditInvitation: canPermitAction(ROLE_FLAG.UPDATE_INVITE, permission),
    canRemoveInvitation: canPermitAction(ROLE_FLAG.DELETE_INVITE, permission)
  }))
}
