// ////////////////////////////////////////////////////
// State  (ROOT)
// ////////////////////////////////////////////////////

/**
 * @returns {object}
 */
const getDefaultState = () => ({
  route: {},
  cognitoUserData: {},
  activePermission: {},
  activeWorkspace: null,
  isAuthorized: true,
  isNotFound: false,
  browzwearAPI: window.BwApi,
  isDarkMode: window.isDarkMode
})

export default getDefaultState
