import Vue from 'vue'
import router from '@/router'
import store from '@/store/store'

/*
 * In order to ensure that development and the production build look the same,
 * resources should be imported in the following order:
 * - Main CSS
 * - UI library (StitchDS in this case)
 * - App.vue
 * - Other components
 *
 * This ensures that the specificity order of the CSS rules is the expected one in every environment.
 * See https://github.com/stitch3d/designhub-frontend/issues/327 for details
 */
import '@/scss/main.scss'
import StitchDS, { StitchAssetBrowser } from '../../stitch-hub-ds'
import App from '@/App'
import FeatureFlagsManager from '../../stitch-hub-vue-feature-flags-manager/src'
import Components from '@/components'
import ElementUI from '../../stitch-hub-ds/src/plugins/ElementUI'
import FormGenerator from '@/plugins/FormGenerator'
import Utils from '@/services/utils'
import Tracking from '@/plugins/Tracking'
import { TRACKER_QUERY_STRINGS } from '@/constants/tracker'
import Sentry from '@/services/sentry'

import stagingIcon from '@/assets/icons/icon-staging.svg'
import developmentIcon from '@/assets/icons/icon-development.svg'
import productionIcon from '@/assets/icons/icon-production.svg'

import baseAPI from '@/services/axios'
import renderFarmMasterAPI from '@/services/renderFarmMasterAPI'

import { mapActions } from 'vuex'

// Initialize early to catch all errors
Sentry.initialize()

// This is only necessary because we remove it from the stitch-hub-ds, so the BOARDS can have a local elementUI without renamed components (ElComponent <> ElementComponent)
Vue.use(ElementUI)

Vue.use(Components)
Vue.use(StitchAssetBrowser, {
  assetTypes: {
    job: {
      apiBaseUrl: renderFarmMasterAPI.getApiBaseUrl(),
      loginUrl: `${window.location.origin}/jobs`
    }
  }
})
Vue.use(FormGenerator)
Vue.use(StitchDS)
Vue.use(FeatureFlagsManager, {
  url: process.env.VUE_APP_FEATURE_FLAGS_JSON
})
Vue.use(
  Tracking,
  {
    mixpanelId: process.env.VUE_APP_MIXPANEL_KEY
  },
  {
    isEnabled: true,
    isDebugEnabled: process.env.NODE_ENV !== 'production',
    router: router
  }
)

Vue.config.productionTip = process.env.NODE_ENV === 'production'
Vue.config.publicPath = process.env.VUE_APP_PUBLIC_PATH || '/'
Vue.config.keyCode = {
  comma: 188
}

Vue.prototype.$hubApiBaseUrl = baseAPI.getApiBaseUrl()
Vue.prototype.$rendersApiBaseUrl = renderFarmMasterAPI.getApiBaseUrl()

// Enable feature flags when running on local API
if (process.env.VUE_APP_API_ENVIRONMENT === 'local') {
  Vue.prototype.$featureFlagsManager.canAccessFeature = () => true
}

const errorMessageDuration = 4000

/**
 * Check for webp support.
 *
 * @returns {boolean}
 */
const supportsWebp = async () => {
  if (!self.createImageBitmap) {
    return false
  }

  const webpData =
    // eslint-disable-next-line no-secrets/no-secrets -- The next line must contain this kind of format as it is a webp file
    'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA='
  const blob = await fetch(webpData).then(r => r.blob())

  return createImageBitmap(blob).then(
    () => true,
    () => false
  )
}

;(async () => {
  Vue.prototype.$webpSupport = await supportsWebp()
})()

/* eslint-disable-next-line no-new -- We need to instantiate our Vue App somehow */
new Vue({
  el: '#app',

  components: { App },

  store,

  router,

  created () {
    // Set callbacks for server calls
    baseAPI.setCallbacks({
      successCallback: () => {},
      errorCallback: error => {
        const response = error.response
        const message = Utils.showError(response)

        if (!response) {
          return
        }

        if ([401, 403].includes(response.status)) {
          this.handleUnauthorized(response)
        } else {
          this.showToaster(response, message)
        }
      }
    })

    renderFarmMasterAPI.setCallbacks({
      successCallback: () => {},
      errorCallback: error => {
        const response = error.response

        if (!response) {
          return
        }

        if ([401, 403].includes(response.status)) {
          console.warn('ERROR 401 || 403')

          this.redirectToLogin()
        } else {
          const message = Utils.showError(response)

          this.showToaster(response, message)
        }
      }
    })
  },

  mounted () {
    this.changeFavicon()
    this.initializeStoreRouteSync()
  },

  methods: {
    ...mapActions(['setIsAuthorized', 'setIsNotFound']),

    /**
     * @param {AxiosResponse} response
     */
    handleUnauthorized (response) {
      if (response.request.responseURL.includes('/whoami')) {
        this.redirectToLogin()
      } else {
        this.setIsAuthorized(false)
      }
    },

    /**
     */
    redirectToLogin () {
      const apiBaseUrl = Vue.prototype.$hubApiBaseUrl
      const identityClientId = process.env.VUE_APP_IDENTITY_CLIENT_ID
      let identityBaseUrl = process.env.VUE_APP_IDENTITY_BASE_URL

      if (identityClientId) {
        if (!identityBaseUrl) {
          identityBaseUrl = `https://${
            process.env.VUE_APP_IDENTITY_SUBDOMAIN
          }.${Utils.getAppDomain()}/`
        }

        const returnUrl = new URL(window.location.href)
        returnUrl.hash = TRACKER_QUERY_STRINGS.LOGIN

        const loginUrl = new URL(`${identityBaseUrl}o/authorize/`)
        loginUrl.searchParams.set('response_type', 'code')
        loginUrl.searchParams.set('client_id', identityClientId)
        loginUrl.searchParams.set('redirect_uri', `${apiBaseUrl}id_auth/login/`)
        loginUrl.searchParams.set('state', returnUrl)

        window.location.assign(loginUrl.href)
      } else {
        console.warn(
          'The location of the STITCH Identity Service could not be determined. Please set "VUE_APP_IDENTITY_BASE_URL" or "VUE_APP_IDENTITY_CLIENT_ID" and try again.'
        )
      }
    },

    /**
     * defaults to productionIcon, since multi-tenant
     */
    changeFavicon () {
      const environmentFavicon = {
        local: developmentIcon,
        staging: stagingIcon,
        'single-tenant-staging': stagingIcon
      }

      const link =
        document.querySelector("link[rel*='icon']") ||
        document.createElement('link')
      link.type = 'image/x-icon'
      link.rel = 'shortcut icon'
      document.getElementsByTagName('head')[0].appendChild(link)

      link.href =
        environmentFavicon[process.env.VUE_APP_ENVIRONMENT] || productionIcon
    },

    /**
     */
    initializeStoreRouteSync () {
      this.$store.dispatch('setRoute', router.currentRoute)

      router.afterEach(route => {
        this.$store.dispatch('setRoute', route)
      })
    },

    /**
     * @param {AxiosResponse} response
     * @param {string}        message
     */
    showToaster (response, message) {
      if (response.status === 404 && response.data && response.data.detail) {
        return
      }

      this.$message({
        message: `Something went wrong: ${message}`,
        type: 'error',
        showClose: true,
        duration: errorMessageDuration
      })
    }
  },

  template: '<App/>',

  render: h => h(App)
}).$mount('#app')
