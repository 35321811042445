import axios from 'axios'
import Utils from '@/services/utils'
import FeatureFlags from '@/services/featureFlags'
import { LIBRARY_ENDPOINT, LIBRARY_TYPE } from '@/constants/libraryType'

let successCallback = null
let errorCallback = null

/**
 * @returns {string}
 */
const getApiBaseUrl = () => {
  // Local development env variable (only)
  let baseUrl = process.env.VUE_APP_API_BASE_URL

  if (!baseUrl) {
    baseUrl = `https://${
      process.env.VUE_APP_HUB_API_SUBDOMAIN
    }.${Utils.getAppDomain()}/`
  }

  console.log(`PYDX-565: baseUrl = ${baseUrl}`)

  return baseUrl
}

export const HTTP = axios.create({
  baseURL: `${getApiBaseUrl()}api/`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Stitch-Client': 'Hub-FE'
  },
  withCredentials: true
})

HTTP.interceptors.response.use(
  response => {
    if (successCallback) {
      successCallback(response)
    }

    return response
  },
  error => {
    if (errorCallback && !axios.isCancel(error)) {
      errorCallback(error)
    }

    return Promise.reject(error)
  }
)

/**
 * @param {object} parameters
 */
const setCallbacks = parameters => {
  successCallback = parameters.successCallback
  errorCallback = parameters.errorCallback
}

/**
 * Prefixes Urls with /workspaces/$workspace.code/$libraryEndpoint when needed
 *
 * @param   {object}  payload
 * @param   {string}  [payload.url = '']
 * @param   {boolean} [payload.checkIsLibrary = false]
 * @param   {string}  [payload.libraryType]
 * @param   {string}  [payload.libraryEndpoint]
 * @param   {string}  payload.activeWorkspaceCode
 *
 * @returns {string}
 */
export const prefixWorkspaceUrls = ({
  url = '',
  libraryType,
  libraryEndpoint,
  activeWorkspaceCode,
  checkIsLibrary = false
}) => {
  let path = '/'

  if (FeatureFlags.canUserAccessWorkspaces()) {
    if (checkIsLibrary && LIBRARY_TYPE.JOB !== libraryType) {
      path += `${LIBRARY_ENDPOINT.workspaces}/${activeWorkspaceCode}/`
    }
  }

  if (libraryType && LIBRARY_ENDPOINT[libraryType]) {
    path += LIBRARY_ENDPOINT[libraryType]
  } else if (libraryEndpoint) {
    path += libraryEndpoint
  }

  return path + url
}

const baseAPI = {
  getApiBaseUrl,
  setCallbacks,
  prefixWorkspaceUrls
}

export default baseAPI
