/**
 * @param {object} state
 * @param {object} notificationsSettings
 */
const setNotificationsSettings = (state, notificationsSettings) => {
  state.notificationsSettings = notificationsSettings
}

/**
 * @param {object} state
 * @param {Array}  notifications
 */
const setNotifications = (state, notifications) => {
  if (state.notifications.length === 0) {
    state.notifications = notifications
  } else {
    state.notifications = state.notifications.concat(notifications)
  }
}

/**
 * @param {object} state
 * @param {number} count
 */
const setUnseenNotificationsCount = (state, count) => {
  state.unseenNotificationsCount = count
}

/**
 * @param {object} state
 * @param {number} count
 */
const setNotificationsCount = (state, count) => {
  state.notificationsCount = count
}

/**
 * @param {object} state
 */
const incrementNotificationsPage = state => {
  state.currentNotificationsPage++
}

/**
 * @param {object}  state
 * @param {boolean} visible
 */
const setNotificationsBadgeVisibility = (state, visible) => {
  state.notificationsBadgeVisibility = visible
}

/**
 * @param {object} state
 * @param {number} notificationId
 */
const setNotificationRead = (state, notificationId) => {
  const index = state.notifications.findIndex(
    notif => notif.id === notificationId
  )

  if (index !== -1) {
    const newNotif = {
      ...state.notifications[index],
      read: true
    }

    state.notifications.splice(index, 1, newNotif)
    state.unseenNotificationsCount--
  }
}

export default {
  setNotificationsSettings,
  setNotifications,
  setUnseenNotificationsCount,
  setNotificationsCount,
  incrementNotificationsPage,
  setNotificationRead,
  setNotificationsBadgeVisibility
}
