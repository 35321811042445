/* eslint-disable camelcase -- Not all variables need to be in camel case in this situation. Some are supposed to be in snake case instead */

import { LIBRARY_TYPE } from '@/constants/libraryType'
import { JOB_STATUS_TEXT } from '@/constants/loadingStatus'
import baseAPI from '@/services/axios'
import placeholderImage from '@/assets/images/img-placeholder.svg'
import { EVENT_FILTER_TYPE } from '@/constants/filterType'

export default {
  from: {
    /**
     * @param   {object} block
     *
     * @returns {object}
     */
    block (block) {
      if (block.isConverted) {
        return block
      }

      // When u3ma becomes the standard, add them (first so that the vsps are the fallback)
      block.files_dict.model3D = [...(block.files_dict.vsp || [])]

      let convertedVersions = []

      if (block.versions) {
        convertedVersions = block.versions
          .map(version => this.blockVersion(version))
          .sort(version => (version.isMain ? -1 : 0))
      }

      const convertedBlock = {
        id: block.id,
        name: block.name,
        type: block.type,
        events: {
          list: [],
          filter: EVENT_FILTER_TYPE.NONE,
          unreadNotificationsCount: block.unread_notifications_count || 0
        },
        subTitle: block.product_group && block.product_group.name,
        libraryType: LIBRARY_TYPE.BLOCK,
        division: block.division && block.division.name,
        divisionId: block.division && block.division.id,
        productGroup: block.product_group && block.product_group.name,
        productGroupId: block.product_group && block.product_group.id,
        createdAt: block.created_at,
        updatedAt: block.updated_at,
        contributor: block.contributor,
        files: block.files,
        filesDict: block.files_dict,
        isConverted: true,
        thumbnail: block.thumbnail || placeholderImage,
        versions: convertedVersions,
        main_model: block.main_model
      }

      const thumbnails = convertedBlock.filesDict.thumbnail

      convertedBlock.colorRun = [
        thumbnails && thumbnails.length > 0
          ? thumbnails[0].assets_url
          : placeholderImage
      ]

      convertedBlock.meta = [
        convertedBlock.division,
        convertedBlock.type && `Block/Shape: ${convertedBlock.type}`,
        convertedBlock.season,
        convertedBlock.contributor && convertedBlock.contributor.email
      ].filter(metaEntry => metaEntry)

      return convertedBlock
    },

    /**
     * @param   {object} fabric
     *
     * @returns {object}
     */
    fabric (fabric) {
      if (fabric.isConverted) {
        return fabric
      }

      // When u3ma becomes the standard, add them (first so that the vsps are the fallback)
      fabric.files_dict.model3D = [
        ...fabric.files_dict.u3ma,
        ...fabric.files_dict.vsp
      ]

      return {
        id: fabric.id,
        name: fabric.name,
        subTitle: fabric.composition,
        libraryType: LIBRARY_TYPE.FABRIC,
        divisions: fabric.divisions,
        divisionIds: fabric.divisions
          ? fabric.divisions.map(division => division.id)
          : [],
        plmCode: fabric.plm_code,
        composition: fabric.composition,
        fabricMaterialSubtype: fabric.fabric_material_subtype,
        fabricMaterialSubtypeId: fabric.fabric_material_subtype_id,
        updatedAt: fabric.updated_at,
        contributor: fabric.contributor,
        files: fabric.files,
        filesDict: fabric.files_dict,
        sustainabilityRating: fabric.sustainability_rating,
        sustainabilityRatingId: fabric.sustainability_rating_id,
        isConverted: true
      }
    },

    /**
     * @param   {object} stitch
     *
     * @returns {object}
     */
    stitch (stitch) {
      if (stitch.isConverted) {
        return stitch
      }

      // When u3ma becomes the standard, add them (first so that the vsps are the fallback)
      stitch.files_dict.model3D = [
        ...stitch.files_dict.u3ma,
        ...stitch.files_dict.vsp
      ]

      return {
        id: stitch.id,
        name: stitch.name,
        subTitle: '',
        libraryType: LIBRARY_TYPE.STITCH,
        updatedAt: stitch.updated_at,
        contributor: stitch.contributor,
        files: stitch.files,
        filesDict: stitch.files_dict,
        isConverted: true
      }
    },

    /**
     * @param   {object} trim
     *
     * @returns {object}
     */
    trim (trim) {
      if (trim.isConverted) {
        return trim
      }

      // When u3ma becomes the standard, add them (first so that the vsps are the fallback)
      trim.files_dict.model3D = [
        ...trim.files_dict.u3ma,
        ...trim.files_dict.vsp,
        ...(trim.files_dict['2D'] || []) // vars can't start with numericals
      ].filter(file => file.parent_trimfile_id === null)

      return {
        id: trim.id,
        name: trim.name,
        subTitle: trim.trim_material_subtype
          ? trim.trim_material_subtype.name
          : '',
        libraryType: LIBRARY_TYPE.TRIM,
        divisions: trim.divisions,
        divisionIds: trim.divisions
          ? trim.divisions.map(division => division.id)
          : [],
        plmCode: trim.plm_code,
        trimMaterialSubtype: trim.trim_material_subtype,
        trimMaterialSubtypeId: trim.trim_material_subtype_id,
        updatedAt: trim.updated_at,
        contributor: trim.contributor,
        files: trim.files,
        filesDict: trim.files_dict,
        isConverted: true
      }
    },

    /**
     * @param   {object}  style
     * @param   {object}  [properties]
     * @param   {boolean} [properties.isDetail]
     *
     * @returns {object}
     */
    style (style, properties = { isDetail: true }) {
      if (style.isConverted) {
        return style
      }

      const convertedStyle = {
        attributes: style.attributes,
        colorRun: style.colorrun || [],
        createdAt: style.created_at,
        groupId: style.group_id,
        id: style.id,
        isConverted: true,
        libraryType: LIBRARY_TYPE.STYLE,
        name: style.name,
        plmCode: style.plm_code,
        priceId: style.price_id,
        seasonId: style.season_id,
        vendorCompanyId: style.assigned_vendor_company,
        subTitle: style.plm_code,
        tags: style.tags || [],
        thumbnail: style.thumbnail || placeholderImage,
        assetStatus: style.asset_status.label,
        assetStatusChangeReason: style.status_change_reason,
        mentionableUsers: style.mentionable_users
      }

      if (properties.isDetail) {
        let convertedVersions = []

        if (style.versions) {
          convertedVersions = style.versions
            .map(version => this.version(version))
            .sort(version => (version.isMain ? -1 : 0))
        }

        const styleDetailProperties = {
          events: {
            list: [],
            filter: EVENT_FILTER_TYPE.NONE,
            unreadNotificationsCount: style.unread_notifications_count || 0
          },
          files: convertedVersions,
          filesDict: JSON.parse(JSON.stringify({ model3D: convertedVersions })),
          group: style.group && style.group.name,
          groupId: style.group && style.group.id,
          price: style.price && style.price.name,
          priceId: style.price && style.price.id,
          season: style.season && style.season.name,
          seasonId: style.season && style.season.id,
          versions: convertedVersions
        }

        styleDetailProperties.meta = [
          styleDetailProperties.price,
          styleDetailProperties.season,
          styleDetailProperties.group,
          ...convertedStyle.tags.map(tag => tag.text),
          ...Object.values(convertedStyle.attributes || {})
        ].filter(metaEntry => metaEntry)

        return { ...convertedStyle, ...styleDetailProperties }
      }

      return convertedStyle
    },

    /**
     * @param   {object} option
     * @param   {number} styleId
     *
     * @returns {object}
     */
    option (option, styleId) {
      if (option.isConverted) {
        return option
      }

      const hubApi = baseAPI.getApiBaseUrl()
      const {
        code,
        id,
        name,
        slideshow_images,
        view_images,
        thumbnail,
        main_color,
        main_color_id,
        version_id,
        style,
        version,
        tags
      } = option

      return {
        code,
        id,
        name,
        images: view_images || [],
        slideshowImages: slideshow_images || [],
        thumbnail: thumbnail || placeholderImage,
        mainColorId: main_color_id || (main_color && main_color.id),
        mainColor: main_color && main_color.name,
        versionId: version_id,
        style,
        version,
        tags,
        zipUrl: styleId
          ? `${hubApi}api/styles/${styleId}/versions/${version_id}/options/${id}/zip`
          : undefined,
        isConverted: true
      }
    },

    /**
     * @param   {object} version
     *
     * @returns {object}
     */
    version (version) {
      if (version.isConverted) {
        return version
      }

      const hubApi = baseAPI.getApiBaseUrl()
      const options = version.options.map(option =>
        this.option(option, version.style_id)
      )

      return {
        autorenderStatus: null,
        autorenderStatusMessage: null,
        contributor: version.contributor,
        createdAt: version.created_at,
        id: version.id,
        isMain: version.is_main,
        renderEngine: version.render_engine,
        model: version.model,
        modelUrl: version.model_url,
        name: version.name,
        options,
        renderJobId: version.render_job_id,
        styleId: version.style_id,
        zipUrl: `${hubApi}api/styles/${version.style_id}/versions/${version.id}/zip`,
        isConverted: true
      }
    },

    /**
     * @param   {object} version
     *
     * @returns {object}
     */
    blockVersion (version) {
      if (version.isConverted) {
        return version
      }

      const hubApi = baseAPI.getApiBaseUrl()

      const views = version.view_images.map(view =>
        this.view(view, version.block_id)
      )

      return {
        autorenderStatus: null,
        autorenderStatusMessage: null,
        contributor: version.contributor,
        createdAt: version.created_at,
        id: version.id,
        isMain: version.is_main,
        renderEngine: version.render_engine,
        model: version.model,
        modelUrl: version.model_url,
        name: version.name,
        views,
        slideshowImages: version.slideshow_images || [],
        renderJobId: version.render_job_id,
        blockId: version.block_id,
        zipUrl: `${hubApi}api/blocks/${version.block_id}/versions/${version.id}/zip`,
        isConverted: true
      }
    },

    /**
     * @param   {object} view
     * @param   {number} blockId
     * @returns {object}
     */
    view (view, blockId) {
      const { id, is_thumbnail, file_url, file, version_id, view_name } = view

      return {
        id,
        isThumbnail: is_thumbnail,
        file,
        fileUrl: file_url,
        versionId: version_id,
        viewName: view_name
      }
    },

    /**
     * @param   {object} job
     *
     * @returns {object}
     */
    job (job) {
      if (job.isConverted) {
        return job
      }

      const filesDict = {}

      if ('preview' in job && job.preview.length > 0) {
        filesDict.thumbnail = job.preview.filter(preview => {
          if (preview.file) {
            return preview.file.includes('png')
          }

          return false
        })
      }

      return {
        id: job.id,
        name: job.name,
        subTitle: JOB_STATUS_TEXT[job.status] || 'Unknown Status',
        libraryType: LIBRARY_TYPE.JOB,
        filesDict: job.files_dict || filesDict,
        files: (job.files && job.files.render) || [],
        updatedAt: job.updated_at,
        createdAt: job.created_at,
        isConverted: true
      }
    }
  }
}
