export const ROLE_FLAG = Object.freeze({
  CREATE_INVITE: 'create-invite',
  CREATE_LIBRARY: 'create-library',
  CREATE_RENDERS: 'create-renders',
  CREATE_STYLE: 'create-style',
  DELETE_INVITE: 'delete-invite',
  DELETE_LIBRARY: 'delete-library',
  DELETE_RENDERS: 'delete-renders',
  DELETE_STYLE: 'delete-style',
  UPDATE_INVITE: 'update-invite',
  UPDATE_LIBRARY: 'update-library',
  UPDATE_RENDERS: 'update-renders',
  UPDATE_STYLE: 'update-style',
  VISIBLE_INVITE: 'visible-invite',
  VISIBLE_LIBRARY: 'visible-library',
  VISIBLE_RENDERS: 'visible-renders',
  VISIBLE_STYLE: 'visible-style',
  VISIBLE_ALL_COMMENT: 'visible-all_comment'
})
