import Vue from 'vue'
import toItemConverter from '@/services/toItemConverter'
import { LIBRARY_TYPE } from '@/constants/libraryType'
import { JOB_STATUS } from '@/constants/loadingStatus'

/**
 * @param {Store.state} state
 * @param {object}      payload
 * @param {object[]}    payload.items
 * @param {string}      payload.libraryType
 */
const setItems = (state, { items, libraryType }) => {
  const updatedItems = [
    ...state[libraryType].items,
    ...items.map(item =>
      toItemConverter.from[libraryType](item, { isDetail: false })
    )
  ]
  state[libraryType].items = [
    ...new Map(updatedItems.map(item => [item.id, item])).values()
  ]
}

/**
 * @param {Store.state} state
 * @param {object}      payload
 * @param {string}      payload.libraryType
 */
const resetItems = (state, { libraryType }) => {
  state[libraryType].paging.currentPage = 1
  state[libraryType].paging.itemsCount = null
  state[libraryType].hasReachedEnd = false
  state[libraryType].items = []
}

/**
 * @param {Store.state} state
 * @param {object}      payload
 * @param {object}      payload.item
 * @param {string}      payload.libraryType
 */
const setItemDetail = (state, { item = null, libraryType } = {}) => {
  Vue.set(state[libraryType], 'itemDetail', item)
}

/**
 * @param {Store.state} state
 * @param {object}      payload
 * @param {object}      payload.status
 * @param {string}      payload.libraryType
 */
const setItemStatus = (state, { status = null, libraryType } = {}) => {
  Vue.set(state[libraryType], 'itemStatus', status)
}

/**
 * @param {Store.state} state
 * @param {object}      payload
 * @param {string}      payload.libraryType
 */
const clearItemStatus = (state, { libraryType }) => {
  Vue.set(state[libraryType], 'itemStatus', null)
}

/**
 * @param {Store.state} state
 * @param {object}      payload
 * @param {string}      payload.libraryType
 */
const clearItemDetail = (state, { libraryType }) => {
  Vue.set(state[libraryType], 'itemDetail', null)
}

/**
 * @param {Store.state} state
 * @param {object}      payload
 * @param {object}      payload.item
 * @param {string}      payload.libraryType
 */
const addItem = (state, { item, libraryType }) => {
  state[libraryType].items.unshift(toItemConverter.from[libraryType](item))
}

/**
 * @param {Store.state} state
 * @param {object}      payload
 * @param {object}      payload.item
 * @param {string}      payload.libraryType
 */
const updateItem = (state, { item, libraryType }) => {
  const index = state[libraryType].items.findIndex(
    singleItem => singleItem.id === item.id
  )

  const processedItem = toItemConverter.from[libraryType](item)

  if (index === -1) {
    state[libraryType].items.unshift(processedItem)
  } else {
    const newItem = {
      ...state[libraryType].items[index],
      ...processedItem
    }

    state[libraryType].items.splice(index, 1, newItem)
  }
}

/**
 * Uses Vue.delete() to trigger reactivity
 *
 * @param {Store.state} state
 * @param {object}      payload
 * @param {number}      payload.id
 * @param {string}      payload.libraryType
 */
const deleteItem = (state, { id: itemId, libraryType }) => {
  const index = state[libraryType].items.findIndex(item => item.id === itemId)

  state[libraryType].items.splice(index, 1)
  Vue.delete(state[libraryType].uploadStatuses, itemId)
}

/**
 * Uses Vue.set() to trigger reactivity
 *
 * @param {Store.state} state
 * @param {object}      payload
 * @param {number}      payload.id
 * @param {object}      payload.status
 * @param {string}      payload.libraryType
 */
const setItemUploadStatus = (
  state,
  { id: itemId, status: uploadStatus, libraryType }
) => {
  Vue.set(state[libraryType].uploadStatuses, itemId, uploadStatus)
}

/**
 * @param {Store.state}      state
 * @param {object}           payload
 * @param {string}           payload.name
 * @param {boolean}          payload.isMain
 * @param {string}           payload.renderEngine
 * @param {string}           payload.fakeId
 * @param {number}           payload.styleId
 * @param {ContributorShape} payload.contributor
 * @param {string}           payload.libraryType
 */
const setVersionsUploadInProgress = (
  state,
  { name, renderEngine, isMain, fakeId, styleId, contributor, libraryType }
) => {
  let temporaryVersions = state[libraryType].versionsUploadInProgress[styleId]

  if (temporaryVersions === undefined) {
    temporaryVersions = []
  }

  temporaryVersions.push({
    autorenderStatus: JOB_STATUS.UPLOADING,
    isUploading: true,
    name,
    isMain,
    renderEngine,
    fakeId,
    images: [],
    contributor
  })

  Vue.set(
    state[libraryType].versionsUploadInProgress,
    styleId,
    temporaryVersions,
    contributor
  )
}

/**
 * @param {Store.state} state
 * @param {object}      payload
 * @param {number}      payload.id
 * @param {object}      payload.item
 * @param {string}      payload.status
 * @param {string}      payload.autorenderStatusMessage
 */
const updateVersionAutorenderStatus = (
  state,
  { id: itemId, item, status, autorenderStatusMessage }
) => {
  if (!item) {
    return
  }

  const versions = item.versions
  const versionToUpdate = versions.find(
    version => version.renderJobId === itemId
  )

  if (!versionToUpdate) {
    return
  }

  versionToUpdate.autorenderStatus = status
  versionToUpdate.autorenderStatusMessage = autorenderStatusMessage
}

/**
 * @param {Store.state} state
 * @param {object}      payload
 * @param {number}      payload.styleId
 * @param {string}      payload.fakeId
 * @param {string}      payload.libraryType
 */
const deleteVersionsUploadInProgress = (
  state,
  { styleId, fakeId, libraryType }
) => {
  const temporaryVersions = state[libraryType].versionsUploadInProgress[styleId]

  const index = temporaryVersions.findIndex(
    singleVersion => singleVersion.fakeId === fakeId
  )

  temporaryVersions.splice(index, 1)

  Vue.set(
    state[libraryType].versionsUploadInProgress,
    styleId,
    temporaryVersions
  )
}

/**
 * @param {Store.state} state
 * @param {object}      payload
 * @param {number}      payload.styleId
 * @param {number}      payload.versionId
 * @param {string}      payload.fakeId
 * @param {string}      payload.libraryType
 */
const setAllVersionsImagesUploadInProgress = (
  state,
  { styleId, versionId, fakeId, libraryType }
) => {
  let temporaryImagesVersions =
    state[libraryType].allVersionsImagesUploadInProgress[styleId]

  if (temporaryImagesVersions === undefined) {
    temporaryImagesVersions = []
  }

  temporaryImagesVersions.push({
    version_id: versionId,
    isUploading: true,
    fakeId
  })

  Vue.set(
    state[libraryType].allVersionsImagesUploadInProgress,
    styleId,
    temporaryImagesVersions
  )
}

/**
 * @param {Store.state} state
 * @param {object}      payload
 * @param {number}      payload.styleId
 * @param {number}      payload.versionId
 * @param {string}      payload.libraryType
 */
const deleteAllVersionsImagesUploadInProgress = (
  state,
  { styleId, versionId, libraryType }
) => {
  const temporaryVersions =
    state[libraryType].allVersionsImagesUploadInProgress[styleId]
  const index = temporaryVersions.findIndex(
    singleVersion => singleVersion.version_id === versionId
  )

  if (index > -1) {
    Vue.delete(
      state[libraryType].allVersionsImagesUploadInProgress[styleId],
      index
    )
  }
}

/**
 * @param {Store.state} state
 * @param {object}      payload
 * @param {string}      payload.fakeId
 * @param {number}      payload.optionId
 * @param {string}      payload.libraryType
 */
const setAllOptionsImagesUploadInProgress = (
  state,
  { fakeId, optionId, libraryType }
) => {
  let temporaryOptionsImages =
    state[libraryType].allOptionsImagesUploadInProgress[optionId]

  if (temporaryOptionsImages === undefined) {
    temporaryOptionsImages = []
  }

  temporaryOptionsImages.push({
    option_id: optionId,
    isUploading: true,
    fakeId
  })

  Vue.set(
    state[libraryType].allOptionsImagesUploadInProgress,
    optionId,
    temporaryOptionsImages
  )
}

/**
 * @param {Store.state} state
 * @param {object}      payload
 * @param {number}      payload.optionId
 * @param {string}      payload.libraryType
 */
const deleteAllOptionsImagesUploadInProgress = (
  state,
  { optionId, libraryType }
) => {
  const temporaryVersions =
    state[libraryType].allOptionsImagesUploadInProgress[optionId]
  const index = temporaryVersions.findIndex(
    singleVersion => singleVersion.option_id === optionId
  )

  if (index > -1) {
    Vue.delete(
      state[libraryType].allOptionsImagesUploadInProgress[optionId],
      index
    )
  }
}

/**
 * @param {Store.state} state
 */
const resetStyleUnreadNotificationsCount = state => {
  const style = state[LIBRARY_TYPE.STYLE].itemDetail

  Vue.set(style.events, 'unreadNotificationsCount', 0)
}

/**
 * @param {Store.state} state
 * @param {object}      payload
 * @param {object}      payload.sorting
 * @param {string}      payload.libraryType
 */
const setSortingItems = (state, { sorting, libraryType }) => {
  state[libraryType].sorting = sorting
}

/**
 * @param {Store.state} state
 * @param {object}      payload
 * @param {string}      payload.libraryType
 */
const incrementPagingItems = (state, { libraryType }) => {
  state[libraryType].paging.currentPage++
}

/**
 * @param {Store.state} state
 * @param {object}      payload
 * @param {boolean}     payload.hasItemsReachedEnd
 * @param {string}      payload.libraryType
 */
const setHasItemsReachedEnd = (state, { hasItemsReachedEnd, libraryType }) => {
  state[libraryType].hasReachedEnd = hasItemsReachedEnd
}

/**
 * @param {Store.state} state
 * @param {object}      payload
 * @param {number}      payload.itemsCount
 * @param {string}      payload.libraryType
 */
const setPagingItemsCount = (state, { itemsCount, libraryType }) => {
  state[libraryType].paging.itemsCount = itemsCount
}

/**
 * @param {Store.state} state
 * @param {object}      payload
 * @param {string}      payload.filterGroupName
 * @param {number[]}    payload.filterIds
 * @param {string}      payload.libraryType
 */
const setAppliedFilter = (
  state,
  { filterGroupName, filterIds, libraryType }
) => {
  const appliedFilters = state[libraryType].appliedFilters
  const selectedFilterIds = [...filterIds]

  Vue.set(appliedFilters, filterGroupName, selectedFilterIds)
}

/**
 * @param {Store.state} state
 * @param {object}      payload
 * @param {string[]}    payload.filtersToReset
 * @param {string[]}    payload.filtersToKeep
 * @param {string}      payload.libraryType
 */
const resetAppliedFilters = (
  state,
  { filtersToReset, filtersToKeep, libraryType }
) => {
  if (filtersToKeep) {
    state[libraryType].appliedFilters = filtersToKeep.reduce(
      (filters, currentFilter) => {
        if (state[libraryType].appliedFilters[currentFilter] !== undefined) {
          return {
            ...filters,
            [currentFilter]: state[libraryType].appliedFilters[currentFilter]
          }
        } else {
          return filters
        }
      },
      {}
    )
  } else if (filtersToReset === null) {
    state[libraryType].appliedFilters = {}
  } else {
    filtersToReset.forEach(filter => {
      state[libraryType].appliedFilters[filter] = []
    })
  }
}

/**
 * @param {Store.state} state
 * @param {object}      payload
 * @param {object}      payload.filters
 * @param {string}      payload.libraryType
 */
const setAvailableFilters = (state, { filters, libraryType }) => {
  state[libraryType].availableFilters = filters
}

/**
 * @param {Store.state} state
 * @param {object}      payload
 * @param {string}      payload.searchQuery
 * @param {string}      payload.libraryType
 */
const setSearch = (state, { searchQuery, libraryType }) => {
  state[libraryType].search = searchQuery
}

/**
 * @param {Store.state} state
 * @param {string}      activeLibraryType
 */
const setActiveLibraryType = (state, activeLibraryType) => {
  state.activeLibraryType = activeLibraryType
}

/**
 * @param {Store.state} state
 * @param {object}      payload
 * @param {boolean}     payload.hasToScrollToTop
 * @param {string}      payload.libraryType
 */
const setHasToScrollToTop = (state, { hasToScrollToTop, libraryType }) => {
  state[libraryType].hasToScrollToTop = hasToScrollToTop
}

/**
 * @param {Store.state} state
 * @param {object}      payload
 * @param {object}      payload.options
 * @param {string}      payload.libraryType
 */
const setPlmOptions = (state, { options, libraryType }) => {
  state[libraryType].plmOptions = options
}

/**
 * @param {Store.state} state
 * @param {object}      payload
 * @param {object}      payload.events
 */
const setStyleEvents = (state, { events }) => {
  if (!state[LIBRARY_TYPE.STYLE].itemDetail) {
    return
  }

  state[LIBRARY_TYPE.STYLE].itemDetail.events.list = events
}

/**
 * @param {Store.state} state
 * @param {object}      payload
 * @param {string}      payload.filter
 */
const setStyleEventsFilter = (state, { filter }) => {
  state[LIBRARY_TYPE.STYLE].itemDetail.events.filter = filter
}

/**
 * @param {Store.state} state
 * @param {object}      payload
 * @param {object[]}    payload.tags
 * @param {string}      payload.libraryType
 */
const setTags = (state, { tags, libraryType }) => {
  state[libraryType].tags = tags.sort((tag1, tag2) =>
    tag1.text.localeCompare(tag2.text)
  )
}

/**
 * @param {Store.state} state
 * @param {object[]}    attributes
 */
const setStylesAttributes = (state, attributes) => {
  state[LIBRARY_TYPE.STYLE].attributes = attributes
}

const mutations = {
  setItems,
  resetItems,
  setItemDetail,
  clearItemDetail,
  addItem,
  updateItem,
  deleteItem,
  setItemUploadStatus,
  setVersionsUploadInProgress,
  updateVersionAutorenderStatus,
  deleteVersionsUploadInProgress,
  setAllVersionsImagesUploadInProgress,
  deleteAllVersionsImagesUploadInProgress,
  setAllOptionsImagesUploadInProgress,
  deleteAllOptionsImagesUploadInProgress,
  resetStyleUnreadNotificationsCount,
  setSortingItems,
  incrementPagingItems,
  setHasItemsReachedEnd,
  setPagingItemsCount,
  setAppliedFilter,
  resetAppliedFilters,
  setAvailableFilters,
  setSearch,
  setActiveLibraryType,
  setHasToScrollToTop,
  setPlmOptions,
  setStyleEvents,
  setStyleEventsFilter,
  setTags,
  setStylesAttributes,
  setItemStatus,
  clearItemStatus
}

export default mutations
